module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://filcon-dk.com/api/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Filcon DK","short_name":"Filcon DK","start_url":"/","lang":"en","display":"standalone","icon":"src/assets/images/favicon.svg","icon_options":{"purpose":"any maskable"},"theme_color":"#151515","background_color":"#151515","localize":[{"start_url":"/ru/","lang":"ru","name":"Filcon DK","short_name":"Filcon DK"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9542c62ec590ae41755d6c25d7fab0db"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
