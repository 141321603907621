import { createTheme } from '@mui/material/styles';

import palette from './palette';

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 320,
      xs: 375,
      sm: 481,
      md: 680,
      lg: 901,
      xl: 1200,
      xxl: 1686,
    },
  },

  typography: {
    color: palette.primary,
    fontFamily: ['Gilroy, sans-serif'].join(','),
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '26px',

    p: {
      margin: 0,
      padding: 0,
    },

    h1: {
      margin: 0,
      padding: 0,
      fontSize: '96px',
      fontWeight: 500,
      lineHeight: '100px',

      '& strong': {
        color: palette.secondary,
        fontSize: '96px',
        fontWeight: 700,
        lineHeight: '100px',
      },

      '@media (max-width: 900px)': {
        fontSize: '40px',
        lineHeight: '48px',

        '& strong': {
          fontSize: '40px',
          lineHeight: '48px',
        },
      },
    },

    h2: {
      margin: 0,
      padding: 0,
      fontSize: '40px',
      fontWeight: 500,
      lineHeight: '48px',

      '& strong': {
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '48px',
      },

      '& span': {
        color: palette.secondary,
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '48px',
      },

      '@media (max-width: 900px)': {
        fontSize: '24px',
        lineHeight: '32px',

        '& strong': {
          fontSize: '24px',
          lineHeight: '32px',
        },

        '& span': {
          fontSize: '24px',
          lineHeight: '32px',
        },
      },
    },

    h3: {
      margin: 0,
      padding: 0,
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '32px',
    },

    body1: {
      margin: 0,
      padding: 0,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18px',
    },

    body2: {
      margin: 0,
      padding: 0,
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
    },

    subtitle1: {
      margin: 0,
      padding: 0,
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '24px',
    },

    subtitle2: {
      margin: 0,
      padding: 0,
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '28px',
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '& *': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            outline: 'none',
            boxSizing: 'border-box',
          },

          '& a': {
            color: 'inherit',
            textDecoration: 'none',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
      },
    },
  },
});

export default theme;
