const palette = {
  primary: '#101010',
  secondary: '#29A37A',
  white: '#FFF',
  mercury: '#DCE8E4',
  starDust: '#9E9E9E',
  ferrariRed: '#FA031F',
  nebula: '#C0DDD4',
  overlay: 'rgba(0, 0, 0, 0.8)',
};

export default palette;
